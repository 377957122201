.title {
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0rem 2rem !important;
    box-sizing: border-box;
}

.segmentImage {
    max-width: 30%;
    width: 20rem;
    height: auto;
}

.segmentInner {
    display: flex;
    max-width: 45rem;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.segmentHeader {
    font-family: 'Poppins', 'sans-serif';
    font-size: 3rem;
    font-style: italic;
    font-weight: 275;
    line-height: normal;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1.5rem 0 !important;
}

.title {
    color: #000;
    font-family: 'Plus Jakarta Sans', 'sans-serif';
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1rem 0 !important;
}

.text {
    color: #707070;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 28px */
    margin: 1rem 0 !important;
    white-space: pre-wrap

}

.startButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.startButton{
    padding: 2rem 2rem !important;
    font-size: 2rem;
    font-weight: 300;
}


/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0rem 0rem !important;
    }

    .segmentImage {
        display: none;
    }

    .segmentInner {
        align-items: center;
    }

    .text {
        margin: 1rem 0rem;
    }
    .startButtonContainer{
        margin-top: 0rem;
    }

}
