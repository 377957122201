.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem !important;
    margin-top: 2rem !important;
}

.lottie {
    max-width: 30rem;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 1rem;
}

.textTitle{
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.textError{
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.textDesc{
    color: #707070;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 28px */
    max-width: 35rem;
    text-align: center;
}

.buttonCreate {
    width: 15rem;
    height: 4rem;
    border-radius: 30px;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    min-width: 5rem;
    border: none;
    outline: none;
    background-image: linear-gradient(to right, #E646B6, #6157FF 33.33333%, #6157FF 66.66666%, #E646B6);
    background-size: 300% 100%;
    background-position: top left;
    transition: all .4s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    margin-top: 1rem !important;
}
.buttonCreate:hover {
    background-position: top left 100%;
    transition: all .4s ease-in-out;
}


.buttonHome{
    width: 12rem;
    color: #FFF;
    padding: .5rem 0 !important;
    margin-top: 1rem !important;
    background-color: #7155F6;
    font-family: 'Poppins', 'Sans';
    border-radius: 16px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-out .2s;
    text-decoration: none;
    text-align: center;
}
.buttonHome:hover{
    background-color: #3f19ff;
    transition: all ease-out .2s;
}
