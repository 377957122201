.footer {
    margin-top: 5rem !important;
    padding: 2rem 0 !important;
    width: 100vw;
    background-color: #e3e3e3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.seperator {
    width: 1px;
    height: 9rem;
    position: absolute;
    color: #C4C4C4;
    background-color: #C4C4C4;
}

.containerInner {
    max-width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding-right: 1rem !important;
    padding-left: 1rem !important;

}
.containerLinks {
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.txtLogo {
    color: #231656;
    font-family: 'Jost', 'sans-serif';
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 129.7%; /* 51.88px */
}

.rights {
    color: #4E4E4E;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 39.7px */
    font-family: 'Poppins', 'sans-serif';
    padding-top: 1.5rem !important;
}

.madeWithLove {
    color: #4E4E4E;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 39.7px */
    font-family: 'Poppins', 'sans-serif';
}

.desc{
    color: #4E4E4E;
    text-align: start;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 39.7px */
    font-family: 'Poppins', 'sans-serif';
    padding-bottom: .5rem !important;
}

.rightsBold {
    color: #4E4E4E;
    text-align: center;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 198.5%; /* 39.7px */
    font-family: 'Poppins', 'sans-serif';
    padding-bottom: 1rem !important;
}


.link {
    background-color: #00000000;
    color: #000;
    text-align: center;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 39.7px */
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
    border-bottom: 1px solid transparent;
    border-color: #00000000;
}
.link:hover,
.link:active,
.link:focus {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
}

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }
    .containerInner {
        align-items: center;
        max-width: 100%;
        text-align: center;
        padding: 1rem 1rem !important;
    }
    .containerLinks {
        padding: 1rem 1rem !important;
        max-width: 100%;
    }
    .seperator {
        display: none;
    }
    .rights {
        padding: 1rem !important;
    }
    .desc{
        text-align: center;
    }
}
