.container {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerSignIn {
    height: 2.5rem;
    min-width: 4rem;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: .5rem !important;
    margin-right: .5rem !important;
}

.menuItemProfileLoading {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    background-color: #E9E9EB;
    border: 3px solid #E9E9EB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuProfileImage {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
}

.menuItemProfile {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    border: 3px solid #E9E9EB;
}

.menuItemProfileActive {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    border: 3px solid #E646B6;
}

.menuItemProfile:hover{
    transition: all .3s ease-in-out;
    border: 3px solid #E646B6;
}

.signInButton{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 300% */
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    padding: 0rem .7rem !important;
}
