.segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0rem 2rem !important;
    box-sizing: border-box;
}

.segment_reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0rem 2rem !important;
    box-sizing: border-box;
}


.segmentImage {
    max-width: 100%;
    width: 20rem;
    height: auto;
}

.segmentInner {
    margin-left: 5rem !important;
    display: flex;
    max-width: 45rem;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.header {
    min-width: 10rem;
    font-family: 'Poppins', sans-serif;
    font-size: 35.23px;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title {
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text {
    color: #707070;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 28px */
    margin: .5rem 0 !important;
    max-width: 35rem;
}

/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .segment {
        flex-direction: column;
    }

    .segment_reverse {
        flex-direction: column;
    }

    .segmentInner {
        text-align: center;
        margin: 0rem !important;
    }
    .header {
        width: 100%;
    }
    .title {
        width: 100%;
    }
    .text {
        margin: 1.5rem 0rem !important;
    }
}
