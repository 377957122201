.imageContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    border-radius: 30px;
    background: #F6F6F6;
    border: 2px solid #00000000;
    text-align: center;
    position: relative;
}


.selectedImage{
    position: relative;
    width: 90%;
    height: 90%;
    padding: 1rem !important;
}

.removeButtonContainer {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 1;
}

.removeButton{
    margin-left: 1rem !important;
    margin-top: 1rem !important;
    width: 2.5rem;
    height: 2.5rem;
    color: #7155F6;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 30px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    cursor: pointer;
    border: #7155F6 solid 2px;
    transition: all ease-out .2s;
    text-decoration: none;
    background: #F6F6F6;
}
.removeButton:hover{
    color: #FFFFFF;
    background: #c71d1d;
    border: #c71d1d solid 2px;
    transition: all ease-out .2s;
}

.youtubeContainer {
    width: 90%;
    height: 80%;
    padding: 1rem 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}




/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {

}

