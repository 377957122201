

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 999;
    display: flex;
    justify-self: center;
    align-items: center;
}

.modalContainer{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
    height: 100%;
    flex: 1;
    pointer-events: none
}

.modal {
    /*min-width: 30%;*/
    /*min-height: 30%;*/
    /* width: 100%; */
    /*height: 80%;*/
    justify-self: center;
    height: fit-content;
    max-height: 80vh;
    overflow-y: scroll;
    padding: 2rem !important;
    border-radius: 35px;
    color: #ffffff00;
    margin: auto !important;
    z-index: 1000;
    pointer-events:auto;

}


/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {

}


.modal h2 {
    margin-top: 0;
}
