.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
}



.giftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 40rem;
    max-height: 40rem;
    pointer-events: none;
}


.clickUI {
    width: 100%;
    height: 100%;
    max-width: 30rem;
    max-height: 27rem;
    background-color: #00FF0000;
    position: absolute;
    margin-top: 3.5rem;
    cursor: pointer;
}


.tapToOpen {
    text-align: center;
    min-width: 10rem;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
    padding: 0.3rem !important;

    position: fixed;
    bottom: 2rem;
}
.tapToOpen:hover {
    background: linear-gradient(57deg, #6157FF 0%, #E646B6 100%);
    transition: all ease-in-out 0.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0.3rem !important;
}
/*.tapToOpen {*/
/*    padding: 15px 45px;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    gap: 10px;*/
/*    border-radius: 21px;*/
/*    background: #324a60;*/
/*    color: #FFF;*/
/*    text-align: center;*/
/*    font-family: 'Poppins', sans-serif;*/
/*    font-size: 16px;*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    line-height: 140%; !* 22.4px *!*/
/*    cursor: pointer;*/
/*    margin-today: 2rem !important;*/
/*}*/
/*.tapToOpen:hover {*/
/*    background: #416181;*/
/*}*/

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    /*.giftContainer {*/
    /*    width: 20rem;*/
    /*    height: 20rem;*/
    /*    margin-today: -5rem !important;*/
    /*}*/

    /*.clickUI {*/
    /*    width: 50%;*/
    /*    height: 20%;*/
    /*}*/
}
