.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    width: 100%;
}

.seperator {
    background-color: #D8D8DA40;
    height: 2px;
    width: 100%;
    margin: 1rem auto !important;
}

.text {
    color: #202020;
    font-family: 'Poppins', 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}


.buttonSignOut{
    width: 10rem;
    min-height: 2rem;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    border-radius: 30px;
    background: #7155F6;
    text-align: center;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
}
.buttonSignOut:hover {
    background: #E646B6;
}

.allGifts{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr)); /* Adjust the minimum and maximum column widths as needed */
    gap: 16px; /* Adjust the gap between grid items as needed */
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center the grid vertically */
    text-decoration: none !important;
}

.giftContainer {
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 12rem;
    margin-top: 1rem !important;
    padding: 1rem 1rem !important;

    border-radius: 20px;
    background: #FFF;
    flex: 1;
    border: 1px solid #C3C3C5;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    justify-self: center;
    cursor: pointer;
}

.gift{
    max-width: 15rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    text-decoration: none !important;
}
.giftImage {
    max-width: 100%;
    max-height: 100%;
}
.giftLink {
    text-decoration: none;
}
.giftTitle{
    text-decoration: none;
    text-align: left;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
}
.giftMessage{
    text-decoration: none;
    text-align: left;
    color: #818181;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
}


.textMyGifts {
    color: #202020;
    font-family: 'Poppins', 'sans-serif';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}
.loadingContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem !important;
}

.createButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem !important;
}

.shareSocialTitle{
    text-decoration: none;
    text-align: left;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    margin-bottom: 1rem !important;
}
.shareSocial{
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}

/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .container {
        padding: 0rem 1rem !important;
        justify-content: center;
        align-items: center;
    }
    .contentContainer {
        padding: 3rem 3rem !important;
        justify-content: flex-start;
        align-items: center;
    }
}

