.magicImageContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 13px;
}

.selectContainer{
    min-width: 15rem;
    max-width: 30rem;
    margin-bottom: .5rem;
}

.title {
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.label {
    width: 100%;
    text-align: left;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 89.6px */
    white-space: pre-wrap;
}


.allImagesContainer{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)); /* Adjust the minimum and maximum column widths as needed */
    gap: 16px; /* Adjust the gap between grid items as needed */
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center the grid vertically */
    text-decoration: none !important;
}

/*.swiperContainer {*/
/*    width: 90vw;*/
/*    box-sizing: border-box;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
.swiperChildContainer{
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E9E9EB;
    background: #fff;
    box-shadow: 0px 4px 6px 0px #00000010, 0px 2px 4px 0px #00000030;
    padding: .3rem !important;
    border-radius: 20px;
    margin: .5rem .5rem !important;
    transition: all .3s ease-in-out;
    justify-self: center;
}

.swiperChildContainer:hover {
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin: 0rem 0rem !important;
    padding: 0.0rem !important;
    /*// transform: scale(1.1); !* Change the scale factor as needed *!*/
    width: 14rem;
    height: 14rem;
    transform-origin: center center; /* Set the pivot point to the center of the element */
    justify-self: center;
}

.swiperImage{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: relative;
    object-fit: cover;
}



.selectButton{
    border-radius: 10px;
    border: 1px solid #D8D8DA;
    padding: .8rem;
    cursor: pointer;
    transition: all ease-in-out .2s;
}
.selectButton:hover{
    background: #f5f5f5;
    transition: all ease-in-out .2s;
}

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .magicImageContainer {
        align-items: center;
    }
    .allImagesContainer{
        grid-template-columns: 1fr;
    }
    .swiperChildContainer{
        width: 70vw;
        height: 10rem;
        margin: .1rem .1rem !important;
        padding: 0.1rem !important;
    }
    .swiperChildContainer:hover{
        width: 70vw;
        height: 10rem;
        margin: .1rem .1rem !important;
        padding: 0.1rem !important;
    }
}
