
.menuItem {
    text-decoration: none;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    transition: all ease-in-out .2s;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #00000000, #00000000);
}

.menuItem:hover,
.menuItem:active {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
    transition: all ease-in-out .2s;
}

.menuItemActive {
    text-decoration: none;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    transition: all ease-in-out .2s;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
}












.menuItemMobile {
    padding-left: 2rem !important;
    width: 100vw;
    text-decoration: none;
    min-height: 4rem;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 31.76px */
    color: #000;
    border-bottom: 2px solid #E9E9EB60;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menuItemActiveMobile {
    padding-left: 2rem !important;
    width: 100vw;
    text-decoration: none;
    min-height: 4rem;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 198.5%; /* 31.76px */
    color: #000;
    border-bottom: 2px solid #E9E9EB60;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menuItemMobile:hover,
.menuItemMobile:active,
.menuItemMobile:focus {
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



