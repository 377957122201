.errorTextContainer{
    margin: 0rem 0 !important;
}
.errorTextHidden{

}
.errorText{
    color: #f61c1c;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.2rem;
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 500;
    transition: all ease-out .2s;
    animation: shake 1s ease-in-out 1;
    text-align: center;
}

@keyframes shakeOff {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
    }
}
@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
    }
}


/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .errorText{
        font-size: 1rem;
    }
}
