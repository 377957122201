.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem !important;
    flex: 1;
}

.contentContainer {
    display: flex;
    width: 65%;
    max-width: 50rem;
    margin-top: 4rem !important;
    padding: 2rem 3rem !important;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border-radius: 20px;
    background: #FFF;
    flex: 1;
    border: 1px solid #C3C3C5;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.seperator {
    background-color: #D8D8DA40;
    height: 2px;
    width: 100%;
    margin: 1rem auto !important;
}


.text {
    color: #202020;
    font-family: 'Poppins', 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}


.giftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 65%;
    max-width: 10rem;
    margin-top: 1rem !important;
    padding: 1rem 3rem !important;

    border-radius: 20px;
    background: #FFF;
    flex: 1;
    border: 1px solid #C3C3C5;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.textMyGifts {
    color: #202020;
    font-family: 'Poppins', 'sans-serif';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}
.createButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem !important;
}

.createButton {
    width: 13rem;
    height: 2.5rem;
    border-radius: 30px;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    min-width: 5rem;
    border: none;
    outline: none;
    background-image: linear-gradient(to right, #E646B6, #6157FF 33.33333%, #6157FF 66.66666%, #E646B6);
    background-size: 300% 100%;
    background-position: top left;
    transition: all .2s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
}

.createButton:hover {
    background-position: top left 100%;
    transition: all .2s ease-in-out;
}



/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .container {
        padding: 0rem 1rem !important;
        justify-content: center;
        align-items: center;
    }
    .contentContainer {
        padding: 3rem 3rem !important;
        justify-content: flex-start;
        align-items: center;
    }
}

