.container{
    width: 100%;
    justify-content: space-between;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 35rem;
}

.loaderContainer{
    padding: 20rem 0 !important;
    flex: 1;
    justify-content: center;
    text-align: center;
    display: flex;
}

.content {
    flex: 1;
}

.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 54px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
}
