/*:root {*/
/*    --gray-00: #FFF;*/
/*    --linear: #FFF;*/
/*}*/

.container {
    position: fixed;
    height: 4rem;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 0px 36px 0px rgba(6, 28, 61, .3);
    z-index: 2;
    padding: 1rem 1rem !important;
    box-sizing: border-box;
}


.langAndProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
}







