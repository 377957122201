.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.containerInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}
.image {
    min-width: 0rem;
    max-width: 40rem;
    width: 40%;
    height: auto;
    margin-top: 10rem !important;
}

.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 67.2px */
}
.text {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.form {
    min-width: 40vw;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.textArea {
    min-width: 30vw;
    max-width: 30rem;
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border-color: #00000000;
    text-align: start;
    resize: none;
    flex: 1;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 2rem !important;
}
.button{
    width: 10rem;
    min-height: 2rem;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    border-radius: 30px;
    background: #7155F6;
    text-align: center;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
}
.button:hover {
    background: #E646B6;
}

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .form {
        width: 90vw;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .image {
        display: none;
    }
    .text {
        padding: 1rem !important;
    }
    .containerInner {
        align-items: center;
        max-width: 90%;
    }
}
