.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}


.titleContainer{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    padding-bottom: .5rem;
}

.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    flex: 1;
}


.textArea {
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border: 2px solid #00000000;
    text-align: start;
    resize: none;
    flex: 1;
    font-size: 16px;
    font-family: 'Poppins', 'sans-serif';
    min-height: 13rem;
    padding: 1rem !important;
    pointer-events: all;
}


.textAreaError {
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border: 2px solid red;
    text-align: start;
    resize: none;
    flex: 1;
    font-size: 16px;
    font-family: 'Poppins', 'sans-serif';
    min-height: 13rem;
    padding: 1rem !important;
    pointer-events: all;
}



.charsRemainContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: .3rem;
}
.charsRemain{
    margin-right: .5rem !important;
    color: #9e9e9e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0px; /* 0% */
    margin-left: .2rem !important;
}
.charsEmoji{
    font-size: 1.4rem;
}


/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {

}
