.adContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 80vw;
}

.title {
    width: 100%;
    text-align: center;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 67.2px */
    padding-top: 1rem !important;
}






/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .buttonsContainer {
        width: 100vw;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        margin-top: 1rem !important;
    }
    .buttonNext{
        margin-bottom: 2rem !important;
        width: 15rem;
        height: 3rem;
    }
    .buttonBack{
        width: 15rem;
        height: 3rem;
    }
}
