.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 80vw;
}

.label {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 2rem !important;
}

.form {
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    flex: 1;
}

.urlContainer{
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem !important;
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    border-radius: 15px;
    text-align: start;
    resize: none;
    color: #89898A;
    background-color: #f3f3f3;
}

.url {
    /* Header/H3 */
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    text-overflow: fade;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.copyButton {
    border-radius: 30px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 300% */
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    min-width: 10rem;
    border: none;
    outline: none;
    background-image: linear-gradient(to right, #E646B6, #6157FF 33.33333%, #6157FF 66.66666%, #E646B6);
    background-size: 300% 100%;
    background-position: top left;
    transition: all .2s ease-in-out;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 1rem !important;
    padding: .8rem 1rem;
}

.copyButton:hover {
    background-position: top left 100%;
    transition: all .2s ease-in-out;
}


.socialButtonsContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem !important;
}

.createButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem !important;
}

.createButton {
    padding: 1.2rem 2rem !important;
}

.textArea {
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border-color: #00000000;
    text-align: start;
    resize: none;
    flex: 2;
    font-size: 16px;
}

.buttonContainer{
    width: 100%;
    margin-top: 2rem !important;
}
.button{
    width: 10rem;
    min-height: 2rem;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    border-radius: 30px;
    background: #7155F6;
    text-align: center;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
}
.button:hover {
    background: #E646B6;
}

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {

    .form {
        border: 1px solid #00000000;
        box-shadow: 0px 4px 6px 0px #00000000, 0px 2px 4px 0px #00000000;
        padding: 0rem !important;
    }

    .urlContainer{
        height: 4rem;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        padding: 2rem !important;
        margin: 0rem !important;
    }

    .copyButton{
        margin-left: 0 !important;
    }

    .label{
        padding: 0 !important;
    }

    .url {
        padding-bottom: .5rem !important;
        font-size: 1.1rem;
    }
}
