
.gradientButton {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: .5rem .7rem !important;

  border-radius: 30px;
  color: #FFF;

  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 300% */
  text-align: center;

  cursor: pointer;

  border: none;
  outline: none;
  background-image: linear-gradient(to right, #E646B6, #6157FF 33.33333%, #6157FF 66.66666%, #E646B6);
  background-size: 300% 100%;
  background-position: top left;

  transition: all .3s ease-in-out;
}

.gradientButton:hover {
  background-position: top left 100%;
  transition: all .3s ease-in-out;
}

.icon{
  max-width: 1rem;
  max-height: 1rem;
  margin: 0 .3rem !important;
}
