.BackgroundGradient {
    background-image: url("../assets/images/top_glass_gradient.png");
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    width: 100%;
    height: 250px;
    position: fixed;
    z-index: -1;
    margin-top: 3rem !important;
}
.BackgroundGradientGiftPage {
    visibility: hidden;
}
