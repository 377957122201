
.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 6rem;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.circleBoarder {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    width: 2.2rem;
    height: 2.2rem;
    border: solid 0.15rem #00000000;
    transition: ease-out all .2s;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;


    width: 1.3rem;
    height: 1.3rem;
    border-radius: 100px;
    border: 4px solid #00000000;
    text-align: center;
    background: #D1D5DB;

    transition: ease-out all .2s;
}

.circleBoarderActive {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    width: 2.2rem;
    height: 2.2rem;
    border: solid 0.15rem #00B312;

    transition: ease-out all .2s;
}

.circleActive {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.3rem;
    height: 1.3rem;
    border-radius: 100px;
    border: 4px solid #00000000;
    background: #00B312;

    transition: ease-out all .2s;
}

.text {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
}



.title {
    color: #777;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    flex: 1;
    max-width: 5rem;
}

.titleActive {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 1;
    max-width: 5rem;
}
