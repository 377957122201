.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    padding-bottom: 10% !important;
    z-index: 1000;
}

.containerInner {
    animation: scaleAndRotate 2s ease-in-out;
    padding: 1rem !important;
    margin: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 6px 23.6px 0px rgba(81, 0, 255, 0.30);
    text-align: center;
    /*max-width: 50rem;*/
    max-width: 80%;
    overflow-y: scroll;
    position: static;
}

@keyframes scaleAndRotate {
    0% {
        opacity: 0;
        transform: scale(0) translateY(50%);
    }
    50% {
        opacity: 1;
        transform: scale(1.1) translateY(-10%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    /*0% {*/
    /*    opacity: 0;*/
    /*    transform: scalex(0) scaley(0) translateY(-10%);*/
    /*    transform-origin: top;*/
    /*}*/
    /*30% {*/
    /*    opacity: 1;*/
    /*    transform: scaley(.3) scaleX(1);*/
    /*}*/
    /*50% {*/
    /*    transform: translateY(10%);*/

    /*}*/
    /*100% {*/
    /*    transform: scaley(1) translateY(0);*/
    /*    transform-origin: top;*/
    /*}*/
}

.title {
    color: #7155F6;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 31.707px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.imageContainer {
    width: 80%;
    height: 80%;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 30rem;
    max-height: 30rem;
    padding: 1rem !important;
    justify-self: center;
    align-self: center;
    flex: 1;
}

.youtubeContainer {
    width: 80%;
    height: 80%;
    padding: 1rem 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.message {
    color: #525252;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 35.2px */
    padding: 1rem !important;
    white-space: pre-wrap
}


.createButtonContainer {
    animation: ShowInDelay 5s ease-in-out;
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
    margin-right: -2rem !important;

    width: 80%;
}

.createButton {

    border-radius: 20px;
    border: 1px solid #7155F6;
    background-color: #FFFFFF;
    text-decoration: none;
    padding: 0.1rem 1.3rem !important;

    color: #7155F6;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 220%; /* 35.2px */

    transition: all .2s ease-in-out;
}

.createButton:hover {
    color: #e4dfff;
    background-color: #7155F6;
    transition: all .2s ease-in-out;
}


@keyframes ShowInDelay {
    0% {
        animation-delay: 3s;
        transition-delay: 3s;
        animation-duration: .2s;
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
