.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}


.title {
    width: 100%;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    flex: 1;
    padding-bottom: .5rem;
}
.titleOptional {
    width: 100%;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: .65rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    flex: 1;
    margin-left: .3rem;
}


























.imageInput {
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border-color: #00000000;
    text-align: start;
    resize: none;
    flex: 2;
    font-size: 16px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem !important;
}






.input-text {
    height: 100%;
    justify-items: center;
    align-content: center;
    align-items: center;
    text-align: center;
    bottom: 50%;
}


