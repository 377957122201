.Demo__container {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); /* Adjust the minimum and maximum column widths as needed */
    gap: 16px; /* Adjust the gap between grid items as needed */
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center the grid vertically */
    margin: auto !important;
    /*place-items: center; !* Shorthand for align-items and justify-content *!*/
}

.Demo__some-network__share-button {

}
