
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    width: 100%;
}

.afterSignInContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 50vw;
    max-width: 50rem;
}

.extraInputField {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.title {
    margin: 0 auto !important;
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.label {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 40;
    line-height: 140%; /* 89.6px */
    white-space: pre-wrap;
    text-align: center;
}

.textRemaining{
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 220%; /* 48.6px */
    text-align: center;
    white-space: pre-wrap
}

.selectButton{
    margin: 1rem auto !important;
    border-radius: 10px;
    border: 1px solid #D8D8DA;
    padding: .8rem;
    cursor: pointer;
    transition: all ease-in-out .2s;
}
.selectButton:hover{
    background: #f5f5f5;
    transition: all ease-in-out .2s;
}

/*.selectContainer{*/
/*    margin-today: 1rem !important;*/
/*    min-width: 50vw;*/
/*}*/

.selectItem {
    background-color: #0d52d9;
}


.signInContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.signInButton{
    padding: .5rem 2rem !important;
}


.signInName {
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.signInTitle {
    margin:  0rem 0 !important;
    padding-top: 1rem !important;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 89.6px */
    white-space: pre-wrap;
    text-align: center;
}
.signInText {
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 220%; /* 48.6px */
    text-align: center;
    white-space: pre-wrap
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}


.label {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding-top: 1rem !important;
    padding-bottom: 0.2rem !important;
    text-align: center;
}

.input {
    width: 90%;
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    border-color: #00000000;
    text-align: start;
    resize: none;
    flex: 2;
    font-size: 16px;
}


.buttonsContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 2rem !important;
}
.buttonNext{
    height: 3rem;
    color: #FFF;
    background-color: #7155F6;
    border: 1px solid #00000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'Sans';
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-out .2s;
}
.buttonNext:hover{
    background-color: #6fc93b;
    transition: all ease-out .2s;
}


.radioContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 3px solid #7155F6;
    background-color: #00000000;
    margin-right: 1rem !important;
    margin-left: 1rem !important;
    transition: all ease-out .2s;
}
.radioChecked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 3px solid #7155F6;
    background-color: #7155F6;
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}


/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .afterSignInContainer{
        margin-left: 0rem !important;
        align-items: center;
        width: 70vw;
        justify-content: flex-start;
    }
}
