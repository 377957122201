.home {
    min-height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rings {
    background-image: url('../../assets/images/top_glass_rings.png');
    background-size: cover;
    background-position: center;
    opacity: 1;
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: -2;
}


/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {

}
