.container {
    align-items: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -5rem !important;
}

.BackgroundGradientGiftPage {
    background-image: url("../../assets/images/top_glass_gradient.png");
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    width: 100%;
    height: 250px;
    position: fixed;
    z-index: 999;
    margin-top: 0rem !important;
    top: 0;
}


.tapToOpen {
    text-align: center;
    min-width: 10rem;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
    padding: 0.3rem !important;

    position: fixed;
    bottom: 2rem;
}
.tapToOpen:hover {
    background: linear-gradient(57deg, #6157FF 0%, #E646B6 100%);
    transition: all ease-in-out 0.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0.3rem !important;
}
