.imageContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    border-radius: 30px;
    background: #F6F6F6;
    border: 2px solid #00000000;
    text-align: center;
    position: relative;
}
.imageContainerIco {
    width: 112px;
    height: 76px;
    padding: 1rem !important;
    margin-top: -3rem;
}
.imageContainerTitle {
    color: #003D7F;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 187.5% */
}
.imageContainerDesc {
    color: #003D7F;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.imageContainerButton {
    display: flex;
    width: fit-content;
    padding: 6px 20px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 3px solid #7155F6;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    cursor: pointer;
    color: #7155F6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    text-transform: uppercase;
    transition: all ease-in-out .3s;
}
.imageContainerButton:hover {
    background-color: #7155F6;
    border: 3px solid #7155F6;
    color: #FFF;
    transition: all ease-in-out .3s;
}

.imageYouTubeButton {
    position: relative;
    width: 75px;
    height: 50px;
    position: relative;
    align-self: flex-end;
    margin-top: .5rem;
    margin-right: 1rem;
    right: 0;
    font-size: 1.8rem;
    border-radius: 20px;
    border-color: #00000000;
    background-color: #ff0000;
    color: #FFF;
    cursor: pointer;
}
.imageYouTubeButton:hover {
    border-color: #000000;
    background-color: #ff0000;
    color: #FFF;
}





/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .imageYouTubeButton {
        position: relative;
        width: 50px;
        height: 40px;
        align-self: flex-end;
        font-size: 1.3rem;
        border-radius: 15px;

    }
    .imageContainerIco {
        width: 56px;
        height: 38px;
    }
    .imageContainerTitle {
        color: #003D7F;
        font-family: 'Poppins', 'sans-serif';
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 187.5% */
    }
    .imageContainerDesc {
        color: #003D7F;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .imageContainerButton {
        padding: 6px 10px !important;
        margin-top: 1rem !important;
    }
}

