.backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 4rem;
    bottom: 0;
    background: rgba(0, 0, 0, 0.30);
    z-index: 1; /* Use a high z-index value */
}

.navContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100Vw;
    z-index: 2; /* Use a high z-index value */
}

.hamburgerContainer {
    flex: 1;
}

.menuItemsContainerMobile {
    text-decoration: none;
    position: absolute;
    display: flex;
    top: 4rem;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    background: white;
    flex: 1;
    z-index: 2; /* Use a high z-index value */
}

.menuItemsList {
    width: 100%;
    min-height: 30vh;
    text-decoration: none;
    list-style-type: none;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
