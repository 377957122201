.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 89.6px */
    padding-top: 5rem !important;
}

.text{
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 220%; /* 48.6px */
    max-width: 70rem;
    white-space: pre-wrap
}

.buttonContactUs {
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 220%; /* 48.6px */
    margin-top: 1rem !important;
    margin-left: .2rem !important;
    margin-right: .2rem !important;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #00000000;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #00000000, #00000000);
    transition: all ease-in-out .2s;
}

.buttonContactUs:hover,
.buttonContactUs:active,
.buttonContactUs:focus {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
}
