.card {
  min-height: 25rem;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 20px;
  border: 1px solid #E9E9EB;
  background: #fff;
  box-shadow: 0px 4px 6px 0px #00000010, 0px 2px 4px 0px #00000030;
  padding: 3rem !important;
}



/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
  .card {
    border: 1px solid #00000000;
    box-shadow: 0px 4px 6px 0px #00000000, 0px 2px 4px 0px #00000000;
    padding: 0rem !important;
  }

}
