.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem !important;
}

.text {
    color: #000;
    text-align: center;
    font-family: 'Poppins', 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    white-space: pre-wrap
}

.getStartedContainer {
    display: flex;
    min-height: 15rem;
    max-height: 30rem;
    margin-top: 3rem !important;
    padding: 2rem 3rem !important;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    border: 3px solid #E646B6;
    background: #FFF;
    flex: 1;
}

.getStartedTitle {
    color: #0F172A;
    font-family: 'Poppins', 'sans-serif';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 25px */
    letter-spacing: 0.2px;
}

.getStartedText {
    color: #0F172A;
    text-align: center;
    font-family: 'Poppins', 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.2px;
}

.googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    background: #2C71F6;
    transition: all ease-in-out .1s;
    cursor: pointer;
    flex: 1;
    max-height: 4rem;
    padding: 1rem 2rem !important;
}
.googleButton:hover{
    background: #0d52d9;
}
.googleButtonText {
    color: #FFF;
    /* body/large/semibold */
    font-family: 'Inter',sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
    letter-spacing: 0.2px;
}

.appleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    background: #000000;
    transition: all ease-in-out .1s;
    cursor: pointer;
    flex: 1;
    max-height: 4rem;
    padding: 1rem 2rem !important;
}
.appleButton:hover{
    background: #464646;
}
.appleButtonText {
    color: #FFF;
    /* body/large/semibold */
    font-family: 'Inter',sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
    letter-spacing: 0.2px;
}

.icon {
    width: 2rem;
    height: 2rem;
}

/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {

}
