.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.textContainer {
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5rem !important;
}

.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 89.6px */
}

.text{
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 220%; /* 48.6px */
    white-space: pre-wrap
}

.contact {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    padding-top: 3rem !important;
}

.buttonContactUs {
    color: #525252;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    margin-top: 1rem !important;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #00000000;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #00000000, #00000000);
    transition: all ease-in-out .2s;
}

.buttonContactUs:hover,
.buttonContactUs:active,
.buttonContactUs:focus {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
}

.image {
    min-width: 20rem;
    width: 30%;
    height: auto;
    margin-top: 10rem !important;
}

/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {
    .mainContainer {
        align-items: center;
    }
    .textContainer {
        max-width: 100%;
        align-items: center;
        padding-top: 3rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .image {
        margin-top: 0 !important;
    }

    .mainContainer {
        flex-direction: column;
    }
}
