.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.profileContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profilePicture {
    width: 60px;
    height: 60px;
    border-radius: 80px;
    border: 2px solid #E646B6;
    background: lightgray 50% / cover no-repeat;
}

.profileContainerTexts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem !important;
}


.title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
}

.desc {
    color: #89898A;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
}



.seperator {
    background-color: #D8D8DA40;
    height: 2px;
    width: 100%;
    margin: 1rem auto !important;
}


.text {
    color: #202020;
    font-family: 'Poppins', 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.magicWandContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.magicWandIcon {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: .5rem !important;
}




.radioContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem !important;
    gap: .5rem;
    width: fit-content;
}
.radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 3px solid #7155F6;
    background-color: #00000000;
    transition: all ease-out .2s;
}
.radioChecked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 3px solid #7155F6;
    background-color: #7155F6;
}



.buttonSignOut{
    width: 10rem;
    height: 3rem;
    min-height: 2rem;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    border-radius: 30px;
    background: #7155F6;
    text-align: center;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
}
.buttonSignOut:hover {
    background: #E646B6;
}







/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .profileContainer {
        flex-direction: column;
        align-items: center;
    }
    .profileContainerTexts {
        align-items: center;
        padding-top: 1rem !important;
        padding-left: 0rem !important;
    }
    /*.profilePicture {*/
    /*    margin-today: 1rem !important;*/
    /*}*/
    /*.profileUserName {*/
    /*    font-size: clamp(0.5rem, 1vw + 0.75rem, 1rem);*/
    /*    text-align: center;*/
    /*    width: 100%;*/
    /*}*/
    .profileEmail {
        font-size: clamp(0.3rem, 1vw + 0.75rem, 1.2rem);
    }

}
