
.howItWorks {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem !important;
    text-align: center;
}

.howItWorksText {
    color: #000;
    font-family: 'Poppins', 'sans-serif';
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 67.2px */
}

.howItWorksLine {
    width: 100%;
    border-radius: 33px;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    height: .5rem;
}
