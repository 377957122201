.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loaderContainer{
    display: flex;
    width: 100vw;
    height: 30vh;
    justify-content: center;
    align-items: center;
}

.blogsContainer{
    display: flex;
    width: 80vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogItemContainer{
    text-decoration: none;

    margin-top: 3rem;
    width: fit-content;
    height: fit-content;
    max-width: 45rem;
    max-height: 40rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 20px;
    border: 1px solid #E9E9EB;
    background: #fff;
    box-shadow: 0px 4px 6px 0px #00000010, 0px 2px 4px 0px #00000030;
    padding: 1rem !important;
    cursor: pointer;
    transition: ease-in-out all .3s;
}
.blogItemContainer:hover{
    scale: 1.1;
    transition: ease-in-out all .3s;
}


.blogTextsContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0rem 1rem;
}

.blogItemImage{
    min-width: 200px;
    min-height: 200px;
    max-width: 40%;
    max-height: 400px;
    border-radius: 20px;
    margin-bottom: 0rem;
}

.blogItemTitle{
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.blogItemDate{
    color: #89898A;
    font-family: 'Poppins', 'sans-serif';
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    margin: 1rem 0;
}

.blogItemReadButton{
    align-self: flex-end;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}



/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .blogItemContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .blogItemImage{
        max-width: 100%;
        max-height: 80%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }
    .blogItemContainer:hover{
        scale: 1;
        transition: ease-in-out all 0s;
    }
}
