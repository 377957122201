.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.loader{
    margin-top: -10rem;
    padding-bottom: 10rem;
}
.selectContainer{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr)); /* Adjust the minimum and maximum column widths as needed */
    gap: 6px; /* Adjust the gap between grid items as needed */
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center the grid vertically */
    text-decoration: none !important;
    margin-top: 1rem;
}
.selectItem{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #FFFFFF;
    background: #fff;
    box-shadow: 0px 2px 3px 0px #00000010, 0px 1px 2px 0px #00000030;
    padding: .3rem !important;
    border-radius: 20px;
    transition: all .3s ease-in-out;
    justify-self: center;
    font-size: 2rem;
}
.selectItemSelected {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #2aa92a;
    background: #fff;
    box-shadow: 0px 2px 3px 0px #00000010, 0px 1px 2px 0px #00000030;
    padding: .3rem !important;
    border-radius: 20px;
    transition: all .3s ease-in-out;
    justify-self: center;
    font-size: 2rem;
    cursor: pointer;
}

.selectItem:hover {
    transition: all .3s ease-in-out;
    cursor: pointer;
    /*// transform: scale(1.1); !* Change the scale factor as needed *!*/
    width: 3rem;
    height: 3rem;
    transform-origin: center center; /* Set the pivot point to the center of the element */
    justify-self: center;
}

.colorImageContainer {
    width: 3rem;
    height: 3rem;
}
.colorImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}


.titleContainer{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: .5rem;
}

.title {
    width: 99%;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    flex: 1;
    margin-top: 1rem;
    margin-bottom: -.5rem;
    text-align: left;
}
