
.youtubeModalContainer {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: center;
    width: 40vw;
}
.inputYoutube {
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    text-align: start;
    resize: none;
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid #00000000;
    width: 95%;
}
.inputYoutubeError {
    padding: 1rem !important;
    border-radius: 16px;
    background: rgba(220, 220, 220, 0.20);
    text-align: start;
    resize: none;
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    width: 95%;
    border: 2px solid red;
}

.loader{
    margin-left: 1rem;
}

.title {
    width: 100%;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    flex: 1;
    padding-bottom: .5rem;
}

.hint {
    width: 100%;
    color: #b2b2b2;
    font-family: 'Poppins', sans-serif;
    font-size: .7rem;
    font-style: normal;
    font-weight: 600;
    flex: 1;
    padding-bottom: .5rem;
}
.errorText {
    width: 100%;
    color: red;
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    flex: 1;
    padding-top: .2rem;
    padding-bottom: .5rem;
}

.imageContainerButton {
    display: flex;
    width: fit-content;
    padding: 6px 20px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 3px solid #7155F6;
    margin-top: 1rem !important;
    cursor: pointer;
    color: #7155F6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    text-transform: uppercase;
    transition: all ease-in-out .3s;
}
.imageContainerButton:hover {
    background-color: #7155F6;
    border: 3px solid #7155F6;
    color: #FFF;
    transition: all ease-in-out .3s;
}



/* Desktop styles */
@media (min-width: 768px) {
}

/* Mobile styles */
@media (max-width: 767px) {

    .youtubeModalContainer {
        width: 70vw;
    }
}

