.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 28px */
    margin-top: -.8rem;
    text-align: center;
    max-width: 40rem;
    padding: 0 2rem;
}
