.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.loaderContainer{
    display: flex;
    width: 100vw;
    height: 90vh;
    justify-content: center;
    align-items: center;
}

.blogDate{
    color: #89898A;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    margin-top: -1rem;
}

.blogMainImage{
    margin-top: 3rem;
    max-width: 80%;
    max-height: 400px;
    border-radius: 20px;
}

.blogContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    max-width: 640px;
}

.h1 {
    align-self: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 3rem;
}
.h2{
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    white-space: pre-wrap;
    text-align: start;
}
.p {
    color: #1c1c1c;
    font-family: 'Roboto', 'sans-serif';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 28px */
    white-space: pre-wrap;
    text-align: start;
    max-width: 100%;
}
.p a {

}


.p a {
    color: #1c1c1c;
    font-family: 'Roboto', 'sans-serif';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 28px */
    white-space: pre-wrap;
    text-align: start;
    max-width: 100%;
    text-decoration: none;

    cursor: pointer;
    border: none;
    outline: none;

    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #6157FF, #E646B6);
    transition: all ease-in-out .2s;
    border-image-slice: 1;
}

.p a:hover {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #E646B6, #6157FF);
    border-image-slice: 1;
}

.image {
    max-width: 100%;
    border-radius: 10px;
}
.space {
    height: 2rem;
}




.startButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.startButton{
    padding: 1rem 2rem !important;
    font-size: 2rem;
    font-weight: 300;
}


/* Desktop styles */
@media (min-width: 768px) {

}

/* Mobile styles */
@media (max-width: 767px) {
    .blogContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        max-width: 80%;
    }

}
