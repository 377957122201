.container{
    flex: 1;
    height: 3px;
    margin-bottom: 3rem !important;
    background-color: #D1D5DB;
    transition: all .5s ease-in-out;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.line {
    position: relative;
    width: 10%;
    height: 100%;
    background-color: #00B312;
    transition: all .5s ease-in-out;
}
