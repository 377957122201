.navContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0 1rem !important;
}

.menuItemsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    flex: 1;
    padding-left: 2rem !important;
}

.logo {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    background-image: linear-gradient(to right, #E646B6, #6157FF 33.33333%, #6157FF 66.66666%, #E646B6);
    background-size: 300% 100%;
    background-position: top left;
    transition: all 1s ease-in-out;
}
.logo:hover {
    background-position: top left 100%;
    transition: all 1s ease-in-out;
}


