.buttonsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.buttonNextHidden{
    width: 8rem;
    height: 3rem;
    opacity: 0;
    transition: all ease-out .2s;
}
.buttonNext{
    width: 8rem;
    height: 3rem;
    color: #FFF;
    background-color: #6fc93b;
    border: 3px solid #00000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'Sans';
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-out .2s;
}
.buttonNext:hover{
    /*background-color: #7155F6;*/
    background-color: #6fc93b;
    border: 3px solid #7155F6;
    transition: all ease-out .2s;
}

.buttonBackHidden{
    width: 8rem;
    height: 3rem;
    opacity: 0;
    transition: all ease-out .2s;
}
.buttonBack{
    width: 8rem;
    height: 3rem;
    color: #7155F6;
    background-color: #00000000;
    border-color: #00000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'Sans';
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-out .2s;
}
.buttonBack:hover{
    color: #7155F6;
    background-color: #00000000;
    border-color: #7155F6;
    transition: all ease-out .2s;
}

