.languageContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.languageButton {
    display: flex;
    width: fit-content;
    margin-right: 1rem !important;
    padding: .4rem .5rem !important;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #D8D8DA;
    cursor: pointer;
    background-color: #00000000;
    transition: all ease-out 0.2s;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;

}

.languageButton:hover {
    background-color: #e1e1e1;
}

.languageIcon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem !important;
}
