.modalContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.modalTitle{
    font-family: 'Plus Jakarta Sans', serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(104deg, #FB3030 20%, #6560FD 140%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1rem 2rem !important;
}
.modalText{
    color: #707070;
    font-family: 'Poppins', 'sans-serif';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 28px */
    max-width: 25rem;
    text-align: center;
    margin: 1rem 2rem !important;
    flex: 1;
    white-space: pre-wrap;
}

.modalButton{
    width: 8rem;
    height: 3rem;
    color: #FFF;
    background-color: #7155F6;
    border: 1px solid #00000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', 'Sans';
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-out .2s;
}
.modalButton:hover{
    background-color: #6fc93b;
    transition: all ease-out .2s;
}
