
.loading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.lottie {
    height: 40rem;
    width: 20rem;
    margin-top: -20rem !important;
}

.loadingTitle{
    margin-top: -10rem !important;
    width: 100vw;
    text-align: center;
    min-width: 10rem;
    font-family: 'Poppins', sans-serif;
    font-size: 35.23px;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    background: linear-gradient(57deg, #E646B6 0%, #6157FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.loadingDesc{
    width: 100vw;
    text-align: center;
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

