.container {
    min-width: 50%;
    min-height: 2rem;
    width: 90%;
    max-width: 40rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.stepsContainer {
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.textsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

